import React from 'react';
import InfoBox from './InfoBox';

const MainPage = () => (
  <div>
    <InfoBox />
  </div>
);

export default MainPage;
